import axios from "axios";
import useAuth from "./useAuth";
import { divisaFormat } from "./useDivisas";

const apiLitior = axios.create({
    baseURL: `${process.env.VUE_APP_LITIOR_SERVICE}`,
    headers: {
        "Content-Type": "application/json",
    },
});

export default function useLitior() {
    const { getToken } = useAuth();

    const createPDF = async (data) => {
        const response = await apiLitior.post("v0/render/feria-co-2024", data, {
            headers: { token: getToken() },
        });

        if (response.status !== 201) {
            //Handler Error
            throw Error("Error al crear PDF");
        }

        return response.data.fileName;
    };

    return { createPDF };
}

export function parseSembradoresOrder(data) {
    const { _id, idservice, customerID, totaltax, discounttotaltax, products } =
        data;

    const ferCommoRaw = products.filter(
        (i) =>
            (i.sector === "NC" || i.sector === "ER") &&
            (i.category === "VOLUMEN" || i.category === "SIMPLE")
    );

    const ferEstraRaw = products.filter(
        (i) =>
            (i.sector === "NC" || i.sector === "ER" || i.sector === "GC") &&
            (i.category === "SOLUBLES" ||
                i.category === "RIEGOS" ||
                i.category === "SEMILLAS" ||
                i.category === "FERTICROP" ||
                i.category === "NITROXTEND")
    );

    const agroCommoRaw = products.filter(
        (i) =>
            i.sector === "PC" &&
            (i.category === "DESCUENTO" || i.category === "VOLUMEN")
    );

    const agroEstraRaw = products.filter(
        (i) =>
            i.sector === "PC" &&
            (i.category === "ESTRATÉGICO" || i.category === "FOLIARES")
    );

    const ferCommo = ferCommoRaw.map((i) => ({
        _id: i._id,
        quantity: i.quantity,
        description: i.name,
        pricetax: i.carrito.pricePerBox,
        discountpricetax: i.carrito.pricePerBoxDiscount,
        unitperbox: i.carrito.unitPerBox,
        priceperunit: i.carrito.price,
        discountpriceperunit: i.carrito.priceDiscount,
        totaltax: i.carrito.subTotal,
        discounttotaltax: i.carrito.subTotalDiscount,
    }));

    const ferEstra = ferEstraRaw.map((i) => ({
        _id: i._id,
        quantity: i.quantity,
        description: i.name,
        pricetax: i.carrito.pricePerBox,
        discountpricetax: i.carrito.pricePerBoxDiscount,
        unitperbox: i.carrito.unitPerBox,
        priceperunit: i.carrito.price,
        discountpriceperunit: i.carrito.priceDiscount,
        totaltax: i.carrito.subTotal,
        discounttotaltax: i.carrito.subTotalDiscount,
    }));

    const agroCommo = agroCommoRaw.map((i) => ({
        _id: i._id,
        quantity: i.quantity,
        description: i.name,
        pricetax: i.carrito.pricePerBox,
        discountpricetax: i.carrito.pricePerBoxDiscount,
        unitperbox: i.carrito.unitPerBox,
        priceperunit: i.carrito.price,
        discountpriceperunit: i.carrito.priceDiscount,
        totaltax: i.carrito.subTotal,
        discounttotaltax: i.carrito.subTotalDiscount,
    }));

    const agroEstra = agroEstraRaw.map((i) => ({
        _id: i._id,
        quantity: i.quantity,
        description: i.name,
        pricetax: i.carrito.pricePerBox,
        discountpricetax: i.carrito.pricePerBoxDiscount,
        unitperbox: i.carrito.unitPerBox,
        priceperunit: i.carrito.price,
        discountpriceperunit: i.carrito.priceDiscount,
        totaltax: i.carrito.subTotal,
        discounttotaltax: i.carrito.subTotalDiscount,
    }));

    const hasFertilizantes = ferCommo.length || ferEstra.length ? true : false;
    const hasFerCommo = ferCommo.length ? true : false;
    let totalFerCommo = 0;
    if (hasFerCommo) {
        for (const p of ferCommo) {
            totalFerCommo += p.discounttotaltax;
            p.pricetax = divisaFormat(p.pricetax);
            p.discountpricetax = divisaFormat(p.discountpricetax);
            p.priceperunit = divisaFormat(p.priceperunit);
            p.discountpriceperunit = divisaFormat(p.discountpriceperunit);
            p.totaltax = divisaFormat(p.totaltax);
            p.discounttotaltax = divisaFormat(p.discounttotaltax);
        }
    }
    totalFerCommo = divisaFormat(totalFerCommo);

    const hasFerEstra = ferEstra.length ? true : false;
    let totalFerEstra = 0;
    if (hasFerEstra) {
        for (const p of ferEstra) {
            totalFerEstra += p.discounttotaltax;
            p.pricetax = divisaFormat(p.pricetax);
            p.discountpricetax = divisaFormat(p.discountpricetax);
            p.priceperunit = divisaFormat(p.priceperunit);
            p.discountpriceperunit = divisaFormat(p.discountpriceperunit);
            p.totaltax = divisaFormat(p.totaltax);
            p.discounttotaltax = divisaFormat(p.discounttotaltax);
        }
    }
    totalFerEstra = divisaFormat(totalFerEstra);

    const hasAgroquimicos = agroCommo.length || agroEstra.length ? true : false;
    const hasAgroCommo = agroCommo.length ? true : false;
    let totalAgroCommo = 0;
    if (hasAgroCommo) {
        for (const p of agroCommo) {
            totalAgroCommo += p.discounttotaltax;
            p.pricetax = divisaFormat(p.pricetax);
            p.discountpricetax = divisaFormat(p.discountpricetax);
            p.priceperunit = divisaFormat(p.priceperunit);
            p.discountpriceperunit = divisaFormat(p.discountpriceperunit);
            p.totaltax = divisaFormat(p.totaltax);
            p.discounttotaltax = divisaFormat(p.discounttotaltax);
        }
    }
    totalAgroCommo = divisaFormat(totalAgroCommo);

    const hasAgroEstra = agroEstra.length ? true : false;
    let totalAgroEstra = 0;
    if (hasAgroEstra) {
        for (const p of agroEstra) {
            totalAgroEstra += p.discounttotaltax;
            p.pricetax = divisaFormat(p.pricetax);
            p.discountpricetax = divisaFormat(p.discountpricetax);
            p.priceperunit = divisaFormat(p.priceperunit);
            p.discountpriceperunit = divisaFormat(p.discountpriceperunit);
            p.totaltax = divisaFormat(p.totaltax);
            p.discounttotaltax = divisaFormat(p.discounttotaltax);
        }
    }
    totalAgroEstra = divisaFormat(totalAgroEstra);

    return {
        _id,
        idservice,
        customerID,
        grandtotaltax: divisaFormat(totaltax),
        granddiscount: divisaFormat(totaltax - discounttotaltax),
        granddiscounttotaltax: divisaFormat(discounttotaltax),
        customer: data.customer_attributes.nombrecliente,
        hasFertilizantes,
        hasFerCommo,
        totalFerCommo,
        ferCommo,
        hasFerEstra,
        totalFerEstra,
        ferEstra,
        hasAgroquimicos,
        hasAgroCommo,
        totalAgroCommo,
        agroCommo,
        hasAgroEstra,
        totalAgroEstra,
        agroEstra,
    };
}
