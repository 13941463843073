<template>
    <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-1">
        <div class="border rounded shadow-sm p-2 bg-blur">
            <div class="d-flex justify-content-between">
                <span>SUBTOTAL</span>
                <span>{{ divisaFormat(resumen.subtotal) }}</span>
            </div>
            <div class="d-flex justify-content-between">
                <!-- <span>DESCUENTO</span>
                <span>- {{ divisaFormat(resumen.descuento) }}</span> -->
                <span>&nbsp;</span>
                <span>&nbsp;</span>
            </div>
            <hr class="m-0" />
            <div class="d-flex justify-content-between">
                <span>TOTAL</span>
                <span class="fw-bolder">{{ divisaFormat(resumen.total) }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { divisaFormat } from "@/services/useDivisas";

export default {
    name: "ProductFloatingSumary",
    props: { resumen: Object },
    setup() {
        return { divisaFormat };
    },
};
</script>
<style lang="scss" scoped>
.bg-blur {
    background-color: #f8f8f880;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
}
</style>
