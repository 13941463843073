<template>
    <div>
        <div class="container-fluid pt-3" v-if="pedidos.length">
            <div class="table-responsive">
                <table class="table align-middle">
                    <thead>
                        <tr class="text-center">
                            <th>ESTADO</th>
                            <th>CÓDIGO</th>
                            <th>CÓDIGO SAP</th>
                            <th>TIPO DE ENTREGA</th>
                            <th>TIPO DE PAGO</th>
                            <th>TOTAL</th>
                            <!-- <th>TOTAL CON DESCUENTO</th> -->
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <PedidoRow
                            v-for="p in pedidos"
                            :key="p._id"
                            :pedido="p"
                        />
                    </tbody>
                </table>
            </div>
        </div>
        <div class="list-content container-fluid" v-else>
            <lottie-animation
                path="loading.json"
                :loop="true"
                :autoPlay="true"
                :width="256"
                :height="256"
            />
        </div>
    </div>
</template>
<script>
import { onBeforeMount } from "vue";
import PedidoRow from "@/components/pedidos/PedidoRow.vue";
import usePedidos from "@/services/usePedidos";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
    name: "PedidosView",
    components: { PedidoRow, LottieAnimation },
    setup() {
        const { pedidos, getPedidos } = usePedidos();
        onBeforeMount(() => {
            getPedidos();
        });
        return { pedidos };
    },
};
</script>
<style lang="scss" scoped>
th,
td {
    font-size: 0.78rem;
}
@media (min-width: 1088px) {
    td {
        font-size: 1rem;
    }
}
</style>
