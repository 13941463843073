<template>
    <div>
        <LoaderAnimation :data="'loading.json'" v-if="showLoading" />
        <div class="container pt-3" v-else>
            <div class="row mb-3">
                <div class="col-12 text-center">
                    <h1 class="fs-3">RESUMEN DEL PEDIDO</h1>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-12 d-flex justify-content-center">
                    <ProductSumary />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h3 class="fs-4">FERTILIZANTES</h3>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12 justify-content-center">
                    <div>
                        <p>Volumen</p>
                        <div>
                            <FertillizantesSumaryList
                                :items="fertCommoFiltered"
                                @onUpdate="handleUpdateProduct"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12 justify-content-center">
                    <div>
                        <p>Especialidad</p>
                        <div>
                            <FertillizantesSumaryList
                                :items="fertEstratFiltered"
                                @onUpdate="handleUpdateProduct"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h3 class="fs-4">AGROQUÍMICOS Y FOLIARES</h3>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12 justify-content-center">
                    <div>
                        <p>Volumen</p>
                        <div>
                            <AgroquimicosSumaryList
                                :items="agroqCommoFiltered"
                                @onUpdate="handleUpdateProduct"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12 justify-content-center">
                    <div>
                        <p>Especialidad</p>
                        <div>
                            <AgroquimicosSumaryList
                                :items="agroqEstratFiltered"
                                @onUpdate="handleUpdateProduct"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3 px-1">
                <div class="d-flex justify-content-end">
                    <button
                        :disabled="!itemsOnCart.length"
                        class="btn btn-primary text-white rounded-pill px-5"
                        @click="handleConfirmar"
                    >
                        Confirmar Pedido
                    </button>
                </div>
            </div>
        </div>
        <DeliverySelectorModal
            :modal-id="modalId"
            @onConfirm="handleSelectDelivery"
            @onClose="() => handleCloseDelivery()"
        />
    </div>
</template>
<script>
import { computed, ref } from "vue";
import { useVfm } from "vue-final-modal";
import ProductSumary from "@/components/checkout/ProductSumary.vue";
import FertillizantesSumaryList from "@/components/checkout/FertillizantesSumaryList.vue";
import AgroquimicosSumaryList from "@/components/checkout/AgroquimicosSumaryList.vue";
import DeliverySelectorModal from "@/components/modals/DeliverySelectorModal.vue";
import LoaderAnimation from "@/components/LoaderAnimation.vue";
import useCatalogo from "@/services/useCatalogo";
import useAuth from "@/services/useAuth";
import usePedidos from "@/services/usePedidos";
import { useRouter } from "vue-router";
import useDescuento from "@/services/useDescuento";
import useSession from "@/services/useSession";

export default {
    name: "CheckoutView",
    components: {
        ProductSumary,
        FertillizantesSumaryList,
        AgroquimicosSumaryList,
        DeliverySelectorModal,
        LoaderAnimation,
    },
    setup() {
        const { deleteSession } = useSession();
        const { getUser, updateUser, resetSystem, getCustomerSAP } = useAuth();
        const { items, itemsOnCart, updateItem, resetCart } = useCatalogo();
        const { escalaDescuento } = useDescuento();
        const { procesarPedido } = usePedidos();
        const vfm = useVfm();
        const modalId = Symbol("modalId");
        const showLoading = ref(false);
        const router = useRouter();

        const handleOpenLoader = () => {
            showLoading.value = true;
        };
        const handleCloseLoader = () => {
            showLoading.value = false;
        };

        const fertCommoFiltered = computed(() => {
            return items.value.filter(
                (i) =>
                    (i.sector === "NC" || i.sector === "ER") &&
                    (i.category === "VOLUMEN" || i.category === "SIMPLE") &&
                    i.quantity > 0
            );
        });
        const fertEstratFiltered = computed(() => {
            return items.value.filter(
                (i) =>
                    (i.sector === "NC" ||
                        i.sector === "ER" ||
                        i.sector === "GC") &&
                    (i.category === "SOLUBLES" ||
                        i.category === "RIEGOS" ||
                        i.category === "SEMILLAS" ||
                        i.category === "FERTICROP" ||
                        i.category === "NITROXTEND") &&
                    i.quantity > 0
            );
        });
        const agroqCommoFiltered = computed(() => {
            return items.value.filter(
                (i) =>
                    i.sector === "PC" &&
                    (i.category === "DESCUENTO" || i.category === "VOLUMEN") &&
                    i.quantity > 0
            );
        });
        const agroqEstratFiltered = computed(() => {
            return items.value.filter(
                (i) =>
                    i.sector === "PC" &&
                    (i.category === "ESTRATÉGICO" ||
                        i.category === "FOLIARES") &&
                    i.quantity > 0
            );
        });

        const handleCreateOrder = async () => {
            try {
                handleOpenLoader();
                await procesarPedido();
                await resetCart();
                await resetSystem();
                deleteSession(getCustomerSAP());
                handleCloseLoader();
                router.replace("/pedidos");
            } catch (error) {
                //handler Error
                console.log(error);
            }
        };

        const handleConfirmar = () => {
            if (
                itemsOnCart.value.find(
                    (i) =>
                        i.sector === "PC" ||
                        i.sector === "ER" ||
                        i.sector === "GC"
                )
            ) {
                vfm.open(modalId);
            } else {
                handleCreateOrder();
            }
        };

        const handleSelectDelivery = (destinatario) => {
            destinatario.value = destinatario;
            console.log("destinatario", destinatario);
            updateUser({
                ...getUser(),
                carrito: { delivery: destinatario },
            });
            vfm.close(modalId);
            handleCreateOrder();
        };

        const handleCloseDelivery = () => {
            vfm.close(modalId);
        };

        const handleUpdateProduct = (value) => {
            updateItem(value);
        };

        return {
            fertCommoFiltered,
            fertEstratFiltered,
            agroqCommoFiltered,
            agroqEstratFiltered,
            vfm,
            modalId,
            showLoading,
            itemsOnCart,
            escalaDescuento,
            handleConfirmar,
            handleSelectDelivery,
            handleCloseDelivery,
            handleUpdateProduct,
        };
    },
};
</script>
<style lang="scss" scoped></style>
