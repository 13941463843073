<template>
    <div>
        <div class="nav-tab position-fixed w-100 pt-2 px-1 px-lg-2 bg-white">
            <ProducListNav v-model="tabSelected" />
        </div>
        <div class="list-content container-fluid" v-if="items.length">
            <div
                class="row"
                ref="listContainer"
                v-if="sector === 'NC' || sector.includes('NC')"
            >
                <Transition name="fade" mode="out-in">
                    <ListFertilizantes
                        v-if="tabSelected === 'volumen'"
                        :items="filteredItems"
                        :filter="['SIMPLE', 'VOLUMEN']"
                        :selectedItem="selectedItem"
                        @onUpdate="handleUpdateItem"
                    />
                    <ListFertilizantes
                        v-else-if="tabSelected === 'nuevos'"
                        :items="filteredItems"
                        :filter="[
                            'FERTICROP',
                            'NITROXTEND',
                            'SOLUBLES',
                            'RIEGOS',
                            'SEMILLAS',
                        ]"
                        :selectedItem="selectedItem"
                        @onUpdate="handleUpdateItem"
                    />
                </Transition>
            </div>
            <div class="row" ref="listContainer" v-if="sector === 'PC'">
                <Transition name="fade" mode="out-in">
                    <ListAgroquimicos
                        v-if="tabSelected === 'volumen'"
                        :items="filteredItems"
                        :filter="['VOLUMEN', 'DESCUENTO']"
                        :selectedItem="selectedItem"
                        @onUpdate="handleUpdateItem"
                    />
                    <ListAgroquimicos
                        v-else-if="tabSelected === 'nuevos'"
                        :items="filteredItems"
                        :filter="['ESTRATÉGICO', 'FOLIARES']"
                        :selectedItem="selectedItem"
                        @onUpdate="handleUpdateItem"
                    />
                </Transition>
            </div>
            <div class="row mb-3 ps-floating-container">
                <div
                    class="col-12 ps-floating d-flex justify-content-end"
                    ref="floatingSumary"
                >
                    <ProductFloatingSumary
                        class=""
                        v-show="sumaryIsFloating"
                        :resumen="resumen"
                    />
                </div>
                <div
                    class="col-12 d-flex justify-content-end"
                    ref="estableSumary"
                >
                    <ProductFloatingSumary
                        class=""
                        v-show="!sumaryIsFloating"
                        :resumen="resumen"
                    />
                </div>
            </div>
        </div>
        <div class="list-content container-fluid" v-else>
            <lottie-animation
                path="loading.json"
                :loop="true"
                :autoPlay="true"
                :width="256"
                :height="256"
            />
        </div>

        <PopupModal @onClose="closePopup" :modal-id="modalId" />
    </div>
</template>
<script>
import { computed, ref, onBeforeMount, onMounted } from "vue";
import { useRoute } from "vue-router";
import { usePopup } from "../services/usePopup";
import PopupModal from "@/components/modals/BannerModal.vue";
import ProducListNav from "@/components/product-list/ProducListNav.vue";
import ListAgroquimicos from "@/components/product-list/ListAgroquimicos.vue";
import ListFertilizantes from "@/components/product-list/ListFertilizantes.vue";
import ProductFloatingSumary from "@/components/product-list/ProductFloatingSumary.vue";
import useAuth from "@/services/useAuth";
import useCatalogo from "@/services/useCatalogo";
import useCarrito from "@/services/useCarrito";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
    name: "ProductListView",
    components: {
        ProducListNav,
        ListAgroquimicos,
        ListFertilizantes,
        ProductFloatingSumary,
        LottieAnimation,
        PopupModal,
    },
    props: {
        sector: [String, Array],
    },
    setup(props) {
        const { closePopup, openPopup, modalId } = usePopup();
        const route = useRoute();
        const auth = useAuth();
        const { items, getItems, updateItem } = useCatalogo();
        const { resumen } = useCarrito();
        const tabSelected = ref("volumen");
        const sumaryIsFloating = ref(true);
        const floatingSumary = ref();
        const floatingFlag = ref(true);
        const estableSumary = ref();

        const filteredItems = computed(() => {
            // Filter Items
            if (items.value)
                return items.value.filter((i) => {
                    if (Array.isArray(props.sector)) {
                        return (
                            props.sector.includes(i.sector) && i.status === 1
                        );
                    }
                    return i.sector === props.sector && i.status === 1;
                });
            return [];
        });

        const selectedItem = computed(() => {
            if (route.query.sku) {
                return filteredItems.value.find((i) => {
                    if (i.sku === route.query.sku) {
                        tabSelected.value =
                            i.category === "COMMODITY" ? "volumen" : "nuevos";
                        return true;
                    }
                });
            }
            return undefined;
        });

        const handleTabSelected = (value) => {
            tabSelected.value = value;
        };

        const handleScroll = () => {
            if (floatingSumary.value) {
                const floatingRect =
                    floatingSumary.value.getBoundingClientRect();
                const fijoRect = estableSumary.value.getBoundingClientRect();

                if (floatingFlag.value) {
                    floatingFlag.value = false;
                    setTimeout(() => {
                        if (sumaryIsFloating.value) {
                            sumaryIsFloating.value =
                                floatingRect.top < fijoRect.top;
                        } else {
                            sumaryIsFloating.value =
                                floatingRect.top - 100 < fijoRect.top;
                        }
                        floatingFlag.value = true;
                    }, 30);
                }
            }
        };

        onBeforeMount(() => {
            if (!auth.checkSession()) {
                console.log("checksession false");
                auth.closeSession();
            }
            getItems();
        });

        onMounted(() => {
            document.addEventListener("scroll", handleScroll);
            if (route.query.sku) {
                console.log("route.query.sku", route.query.sku);
            }
            setTimeout(() => {
                openPopup();
            }, 1000);
        });

        const handleUpdateItem = (item) => {
            updateItem(item);
        };

        return {
            tabSelected,
            floatingSumary,
            estableSumary,
            sumaryIsFloating,
            filteredItems,
            resumen,
            handleTabSelected,
            handleUpdateItem,
            items,
            selectedItem,
            modalId,
            closePopup,
        };
    },
};
</script>
<style lang="scss">
.nav-tab {
    z-index: 1;
}
.list-content {
    padding-top: 119px;
}
.fade-leave-to,
.fade-enter-from {
    opacity: 0;
}
.fade-leave-from,
.fade-enter-to {
    opacity: 1;
}
.fade-leave-active,
.fade-enter-active {
    transition: opacity 0.2s;
}
.ps-floating-container {
    height: 100px;
}
.ps-floating {
    position: fixed;
    bottom: 20px;
    left: 0px;
    width: 100%;
}
@media (min-width: 1088px) {
    .list-content {
        padding-top: 171px;
    }
}
</style>
