import axios from "axios";
import { ref } from "vue";

const apiFeria = axios.create({
    baseURL: `${process.env.VUE_APP_MAIN_SERVICE}`,
    headers: {
        "Content-Type": "application/json",
    },
});

const customerSAP = ref();
const token = ref();
const user = ref();

export default function useAuth() {
    //Token
    const setToken = (value) => {
        token.value = value;
        persistToken(value);
    };
    const getToken = () => token.value;
    const persistToken = (value) => {
        localStorage.setItem("token", value);
    };
    const recoverToken = () => {
        token.value = localStorage.getItem("token");
    };

    //CustomerSAP
    const setCustomerSAP = (value) => {
        customerSAP.value = value;
        persistCustomerSAP(value);
    };
    const getCustomerSAP = () => customerSAP.value;
    const persistCustomerSAP = (value) => {
        localStorage.setItem("customerSAP", value);
    };
    const recoverCustomerSAP = () => {
        customerSAP.value = localStorage.getItem("customerSAP");
    };

    //User
    const getUser = () => user.value;
    const persistUser = (user) => {
        localStorage.setItem("user", JSON.stringify(user));
    };
    const recoverUser = () => {
        user.value = JSON.parse(localStorage.getItem("user"));
        console.log("user.value", user.value);
    };
    const getUserData = async (value) => {
        try {
            const response = await apiFeria.get(`customers/getinfo/${value}`, {
                headers: { token: token.value },
            });
            if (response.status !== 200) {
                console.log("response.status", response.status);
                closeSession();
                throw Error("USER_NOT_FOUNT");
            }

            user.value = response.data.getCompanias[0];
            persistUser(response.data.getCompanias[0]);
        } catch (error) {
            throw Error("USER_NOT_FOUNT");
        }
    };
    const updateUser = (value) => {
        user.value = value;
    };

    //Session
    const setupSession = async (inputCustomerSap, inputToken = "token") => {
        if (!inputCustomerSap || !inputToken) {
            console.log("No DATA SESSION");
            closeSession();
        }
        setCustomerSAP(inputCustomerSap);
        setToken(inputToken);
        await getUserData(inputCustomerSap);
    };

    const recoverSession = async () => {
        recoverCustomerSAP();
        recoverUser();
        recoverToken();
        return;
    };

    const checkSession = () => {
        console.log(`${user.value} && ${customerSAP.value}`);
        if (user.value && customerSAP.value) {
            return true;
        }
        return false;
    };

    const closeSession = () => {
        token.value = null;
        localStorage.removeItem("token");
        customerSAP.value = null;
        localStorage.removeItem("customerSAP");
        user.value = null;
        localStorage.removeItem("user");
        sessionStorage.setItem("wasOpened", false);
        window.location.href = `${process.env.VUE_APP_TIENDA_URL}customer/account/logout/`;
    };

    const resetSystem = async () => {
        await getUserData(customerSAP.value);
    };

    return {
        user,
        setToken,
        getToken,
        getCustomerSAP,
        getUser,
        updateUser,
        setupSession,
        recoverSession,
        closeSession,
        checkSession,
        resetSystem,
    };
}
