<template>
    <tr>
        <td>
            <div
                class="column-product d-flex flex-column flex-lg-row align-items-center justify-content-certer text-center"
            >
                <div
                    class="product-img"
                    :style="{
                        'background-image': `url(${urlImg}${item.image})`,
                    }"
                ></div>
                <span class="product-title"
                    ><strong>{{ item.description }}</strong></span
                >
            </div>
        </td>
        <!-- <td class="text-center">
            <span class="text-danger text-nowrap">{{
                divisaFormat(item.pricetax)
            }}</span>
        </td> -->
        <td class="text-center">
            <span class="text-success text-nowrap">{{
                divisaFormat(item.discountpricetax)
            }}</span>
        </td>
        <td class="text-center">
            <span class="text-dark text-nowrap">{{ item.unitperbox }}</span>
        </td>
        <!-- <td class="text-center">
            <span class="text-danger text-nowrap">{{
                divisaFormat(Math.trunc(item.pricetax / item.unitperbox))
            }}</span>
        </td> -->
        <td class="text-center">
            <span class="text-success text-nowrap">{{
                divisaFormat(
                    Math.trunc(item.discountpricetax / item.unitperbox)
                )
            }}</span>
        </td>
        <td class="text-center">
            <span class="text-dark text-nowrap">{{ item.quantity }}</span>
        </td>
        <!-- <td class="text-center">
            <span class="text-danger text-nowrap">{{
                divisaFormat(item.totaltax)
            }}</span>
        </td> -->
        <td class="text-center">
            <span class="text-success text-nowrap">{{
                divisaFormat(item.discounttotaltax)
            }}</span>
        </td>
    </tr>
</template>
<script>
import { divisaFormat } from "@/services/useDivisas";

export default {
    name: "PedidoProductRow",
    props: {
        item: Object,
    },
    setup() {
        return {
            divisaFormat,
            urlImg: "https://storage.googleapis.com/assets_cambiagro/assets/images/",
        };
    },
};
</script>
<style lang="scss" scoped>
.table th:first-child,
.table td:first-child {
    position: sticky;
    left: 0;
    background-color: white;
}
.column-product {
    min-width: 100px;
}
.product-img {
    width: 55px;
    height: 55px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.product-title,
td {
    font-size: 0.65rem;
    text-align: center;
}

@media (min-width: 1088px) {
    .column-product {
        min-width: 200px;
    }
    .product-title {
        font-size: 0.8rem;
    }
    td {
        font-size: 0.8rem;
    }
}
</style>
