<!-- eslint-disable prettier/prettier -->
<template>
    <tr>
        <td class="text-start">
            <span class="fs-6"
                >{{
                    pedido.customer_attributes?.CondPago?.nombrecliente ||
                    pedido.customer_attributes?.nombrecliente
                }}<br />{{ pedido.customerSAP }}</span
            >
        </td>
        <td>{{ pedido.idservice }}</td>
        <td>
            <div
                class="d-flex flex-column align-items-center justify-content-center"
                v-if="
                    pedido.orderSAPsectors &&
                    Object.keys(pedido.orderSAPsectors).length
                "
            >
                <div v-if="pedido.orderSAPsectors?.NC">
                    NC-{{ pedido.orderSAPsectors?.NC }}
                </div>
                <div v-else>-</div>
                <div v-if="pedido.orderSAPsectors?.PC">
                    PC-{{ pedido.orderSAPsectors?.PC }}
                </div>
                <div v-else>-</div>
                <div v-if="pedido.orderSAPsectors?.ER">
                    ER-{{ pedido.orderSAPsectors?.ER }}
                </div>
                <div v-else>-</div>
                <div v-if="pedido.orderSAPsectors?.GC">
                    GC-{{ pedido.orderSAPsectors?.GC }}
                </div>
                <div v-else>-</div>
            </div>
        </td>
        <td>{{ formatDate(pedido.created_at) }}</td>
        <td>
            <span :class="statusClasses">{{ status }}</span>
        </td>
        <!-- <td class="">
            <span class="text-dark">{{ divisaFormat(pedido.total) }}</span>
        </td> -->
        <td class="">
            <span class="text-success">{{
                divisaFormat(pedido.discounttotal)
            }}</span>
        </td>
        <!-- <td class="">
            <span class="text-dark">{{
                divisaFormat(pedido.total - pedido.discounttotal)
            }}</span>
        </td> -->
        <td>
            <button
                class="btn btn-sm btn-primary text-white"
                data-bs-toggle="collapse"
                :data-bs-target="'#collapse-' + pedido.idservice"
                aria-expanded="false"
                :aria-controls="'#collapse-' + pedido.idservice"
                ref="btn"
            >
                Ver Detalle
            </button>
        </td>
    </tr>
    <tr class="collapse" :id="'collapse-' + pedido.idservice">
        <td colspan="11">
            <div class="container-fluid pt-3 ps-3 mb-5">
                <div
                    class="row"
                    v-if="fertCommoFiltered.length || fertEstratFiltered.length"
                >
                    <div class="col-12 text-start">
                        <h3 class="fs-6">FERTILIZANTES</h3>
                    </div>
                </div>
                <div class="row mb-1" v-if="fertCommoFiltered.length">
                    <div class="col-12 justify-content-center">
                        <div>
                            <p class="text-start mb-0 fs-6">Volumen</p>
                            <div>
                                <PedidoFertillizantesList
                                    :items="fertCommoFiltered"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-1" v-if="fertEstratFiltered.length">
                    <div class="col-12 justify-content-center">
                        <div>
                            <p class="text-start mb-0 fs-6">Especialidad</p>
                            <div>
                                <PedidoFertillizantesList
                                    :items="fertEstratFiltered"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="row"
                    v-if="
                        agroqCommoFiltered.length || agroqEstratFiltered.length
                    "
                >
                    <div class="col-12 text-start">
                        <h3 class="fs-6">AGROQUÍMICOS Y FOLIARES</h3>
                    </div>
                </div>
                <div class="row mb-1" v-if="agroqCommoFiltered.length">
                    <div class="col-12 justify-content-center">
                        <div>
                            <p class="text-start mb-0 fs-6">Volumen</p>
                            <div>
                                <PedidoAgroquimicosList
                                    :items="agroqCommoFiltered"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-1" v-if="agroqEstratFiltered.length">
                    <div class="col-12 justify-content-center">
                        <div>
                            <p class="text-start mb-0 fs-6">Especialidad</p>
                            <div>
                                <PedidoAgroquimicosList
                                    :items="agroqEstratFiltered"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </td>
    </tr>
</template>
<script>
import { computed, onMounted, ref } from "vue";
import { divisaFormat } from "@/services/useDivisas";
import { Collapse } from "bootstrap";

import { formatDate } from "@/services/useDate";

import PedidoAgroquimicosList from "../pedidos/PedidoAgroquimicosList.vue";
import PedidoFertillizantesList from "../pedidos/PedidoFertillizantesList.vue";

export default {
    name: "ReporteRow",
    components: { PedidoAgroquimicosList, PedidoFertillizantesList },
    props: {
        pedido: Object,
    },
    setup(props) {
        const btn = ref();
        const status = computed(() => {
            return props.pedido.offersSAP.length > 0
                ? "Completado"
                : "Pendiente";
        });
        const statusClasses = computed(() => {
            return props.pedido.offersSAP.length > 0 ? "text-successs" : "";
        });
        const deliveryType = (sector) => {
            return sector === "PC" ? "A Domicilio" : "Retiro en planta";
        };

        const fertCommoFiltered = computed(() => {
            return props.pedido.products.filter(
                (i) =>
                    (i.sector === "NC" || i.sector === "ER") &&
                    (i.category === "VOLUMEN" || i.category === "SIMPLE") &&
                    i.quantity > 0
            );
        });
        const fertEstratFiltered = computed(() => {
            return props.pedido.products.filter(
                (i) =>
                    (i.sector === "NC" ||
                        i.sector === "ER" ||
                        i.sector === "GC") &&
                    (i.category === "SOLUBLES" ||
                        i.category === "RIEGOS" ||
                        i.category === "SEMILLAS" ||
                        i.category === "FERTICROP" ||
                        i.category === "NITROXTEND") &&
                    i.quantity > 0
            );
        });
        const agroqCommoFiltered = computed(() => {
            return props.pedido.products.filter(
                (i) =>
                    i.sector === "PC" &&
                    (i.category === "DESCUENTO" || i.category === "VOLUMEN") &&
                    i.quantity > 0
            );
        });
        const agroqEstratFiltered = computed(() => {
            return props.pedido.products.filter(
                (i) =>
                    i.sector === "PC" &&
                    (i.category === "ESTRATÉGICO" ||
                        i.category === "FOLIARES") &&
                    i.quantity > 0
            );
        });

        onMounted(() => {
            new Collapse(btn.value);
        });

        return {
            status,
            statusClasses,
            deliveryType,
            divisaFormat,
            formatDate,
            btn,
            fertCommoFiltered,
            fertEstratFiltered,
            agroqCommoFiltered,
            agroqEstratFiltered,
        };
    },
};
</script>
<style lang="scss" scoped>
td {
    font-size: 0.78rem;
    text-align: center;
}

@media (min-width: 1088px) {
    td {
        font-size: 1rem;
    }
}
</style>
