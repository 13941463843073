<template>
    <div class="container-fluid border-bottom">
        <div class="row">
            <div
                class="col-12 d-flex align-items-center header-top"
                style="height: 52px"
            >
                <div class="flex-shrink-1">
                    <a>
                        <img
                            src="https://storage.googleapis.com/assets_cambiagro/assets/logocambiagro.svg"
                            alt="Cambiagro"
                        />
                    </a>
                </div>
                <div class="flex-grow-1"></div>
                <div class="d-flex flex-shrink-1">
                    <div
                        class="d-flex flex-column flex-lg-row align-items-right align-items-lg-center justify-content-center"
                    >
                        <!-- <div
                            class="client-source d-flex align-items-center justify-content-end justify-content-lg-start ms-3 text-primary order-1 order-lg-0"
                        >
                            <i
                                class="fa fa-lg fa-industry-alt me-2 text-dark lh-reset"
                            ></i>
                            <span class="">BODEGA ALPOPULAR MADRID</span>
                        </div> -->
                        <!-- <span class="d-none d-lg-block ms-3">|</span> -->
                        <div
                            class="client-name d-flex align-items-center justify-content-end justify-content-lg-start ms-3 order-0 order-lg-1"
                        >
                            <strong class="text-primary">{{
                                user?.nombre_compania
                            }}</strong>
                            <i class="fal fa-lg fa-user ms-1 lh-reset"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="sub-menu col-12 d-flex text-danger bg-dark text-white"
                :class="{ 'd-none': !showSubheader }"
            >
                <router-link
                    to="/fertilizantes"
                    class="d-flex flex-shrink-1 align-items-center text-white text-decoration-none text-center text-lg-start"
                >
                    <strong>Fertilizantes</strong>
                </router-link>
                <router-link
                    to="/agroquimicos"
                    class="ms-3 d-flex flex-shrink-1 align-items-center text-white text-decoration-none text-center text-lg-start"
                >
                    <strong>Agroquímicos y foliares</strong>
                </router-link>
                <router-link
                    to="/checkout"
                    class="d-flex align-items-center ms-lg-auto ms-4 text-white text-decoration-none text-center text-lg-start"
                    role="button"
                >
                    <i
                        class="d-none d-md-inline-block fal fa-lg fa-shopping-cart me-1 lh-reset"
                    ></i>
                    <strong>Mi Carrito</strong>
                </router-link>
                <router-link
                    to="/pedidos"
                    class="d-flex align-items-center ms-4 text-white text-decoration-none text-center text-lg-start"
                    role="button"
                >
                    <i
                        class="d-none d-md-block fal fa-lg fa-list-ol me-1 lh-reset"
                    ></i>
                    <strong>Mis Pedidos</strong>
                </router-link>
                <div class="d-flex align-items-center ms-4">
                    <span>|</span>
                </div>
                <div
                    @click="handlerCloseSession"
                    class="d-flex align-items-center ms-3 text-center text-lg-start"
                    role="button"
                >
                    <span><strong>Cerrar sesión</strong></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
import useAuth from "@/services/useAuth";
import useSession from "@/services/useSession";

export default {
    name: "WebHeader",
    setup() {
        // eslint-disable-next-line no-unused-vars
        const { deleteSession } = useSession();
        // eslint-disable-next-line no-unused-vars
        const { user, closeSession, getCustomerSAP } = useAuth();
        const route = useRoute();

        const showSubheader = computed(() => {
            if (route.name === "reporte") {
                return false;
            }
            return true;
        });

        const handlerCloseSession = () => {
            closeSession();
            // deleteSession(getCustomerSAP());
        };
        return { user, handlerCloseSession, showSubheader };
    },
};
</script>
<style lang="scss" scoped>
.client-name {
    font-size: 0.9em;
}
.client-source {
    font-size: 0.6em;
}
.sub-menu {
    height: 49px;
    font-size: 0.7rem;
}
.router-link-active {
    background-image: linear-gradient(0deg, rgb(255 0 0) 3px, transparent 3px);
}

@media (min-width: 1088px) {
    .client-source {
        font-size: 0.85em;
    }
    .client-name {
        font-size: 1.1em;
    }
    .sub-menu {
        font-size: 1em;
    }
}
</style>
