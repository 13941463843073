import { computed, watch } from "vue";
import useCatalogo from "./useCatalogo";
import useAuth from "./useAuth";

export default function useDescuento() {
    const { itemsOnCart } = useCatalogo();
    const { getUser } = useAuth();
    const user = getUser();

    const escalaDescuento = computed(() => {
        // Por numero de categorias escogidas

        let categorias = { NC: {}, PC: {}, ER: {}, GC: {} };
        const descuentos = {
            NC: {
                VOLUMEN: "scale1",
                FERTICROP: "scale1",
                NITROXTEND: "scale1",
                SOLUBLES: "scale1",
            },
            ER: {
                RIEGOS: "scale1",
            },
            GC: {
                SEMILLAS: "scale1",
            },
            PC: {
                DESCUENTO: "scale1",
                VOLUMEN: "scale1",
                ESTRATÉGICO: "scale1",
                FOLIARES: "scale1",
            },
        };
        for (const i of itemsOnCart.value) {
            if (i.quantity > 0) {
                categorias[i.sector][i.category] += i.quantity;
            }
        }

        if (Object.keys(categorias.NC).length) {
            if (Object.keys(categorias.NC).includes("VOLUMEN")) {
                descuentos.NC["FERTICROP"] = "scale1";
                descuentos.NC["NITROXTEND"] = "scale1";
                descuentos.NC["VOLUMEN"] = "scale1";
            }

            // if (Object.keys(categorias.NC).includes("RIEGOS")) {
            //     descuentos.NC["SOLUBLES"] = "scale1";
            //     descuentos.NC["RIEGOS"] = "scale1";
            // }

            if (
                Object.keys(categorias.NC).includes("FERTICROP") ||
                Object.keys(categorias.NC).includes("NITROXTEND")
            ) {
                descuentos.NC["FERTICROP"] = "scale2";
                descuentos.NC["NITROXTEND"] = "scale2";
                descuentos.NC["VOLUMEN"] = "scale2";
            }

            if (
                Object.keys(categorias.NC).includes("SOLUBLES") &&
                Object.keys(categorias.ER).includes("RIEGOS")
            ) {
                descuentos.NC["SOLUBLES"] = "scale2";
                descuentos.ER["RIEGOS"] = "scale2";
            }
        }

        if (Object.keys(categorias.PC).length) {
            if (
                Object.keys(categorias.PC).includes("DESCUENTO") ||
                Object.keys(categorias.PC).includes("VOLUMEN") ||
                Object.keys(categorias.PC).includes("ESTRATÉGICO") ||
                Object.keys(categorias.PC).includes("FOLIARES")
            ) {
                descuentos.PC["DESCUENTO"] = "scale1";
                descuentos.PC["VOLUMEN"] = "scale1";
                descuentos.PC["ESTRATÉGICO"] = "scale1";
                descuentos.PC["FOLIARES"] = "scale1";
            }

            if (
                (Object.keys(categorias.PC).includes("DESCUENTO") ||
                    Object.keys(categorias.PC).includes("VOLUMEN")) &&
                (Object.keys(categorias.PC).includes("ESTRATÉGICO") ||
                    Object.keys(categorias.PC).includes("FOLIARES"))
            ) {
                descuentos.PC["DESCUENTO"] = "scale2";
                descuentos.PC["VOLUMEN"] = "scale2";
                descuentos.PC["ESTRATÉGICO"] = "scale2";
                descuentos.PC["FOLIARES"] = "scale2";
            }
        }

        return descuentos;
    });

    watch(
        () => escalaDescuento.value,
        (nVal) => {
            for (const i of itemsOnCart.value) {
                if (
                    i.carrito &&
                    i.carrito.discount !==
                        i.custom_attributes[0].discount[
                            nVal[i.sector][i.category]
                        ]
                ) {
                    const listaPrecio = computed(() => {
                        switch (i.sector) {
                            case "PC":
                                return user.feria.lista_ppc;
                            case "NC":
                            case "ER":
                            case "GC":
                                return user.feria.lista_pnc;
                            default:
                                return "";
                        }
                    });

                    i.carrito.discount = 0;
                    // i.custom_attributes[0].discount[
                    //     nVal[i.sector][i.category]
                    // ];

                    i.carrito.price =
                        i.custom_attributes[0].priceperunit[listaPrecio.value];

                    i.carrito.priceDiscount = i.carrito.price;
                    // Math.trunc(
                    //     i.carrito.price * ((100 - i.carrito.discount) / 100)
                    // );

                    i.carrito.pricePerBox =
                        i.custom_attributes[0].price[listaPrecio.value];

                    i.carrito.pricePerBoxDiscount = i.carrito.pricePerBox;
                    // Math.trunc(
                    //     // eslint-disable-next-line prettier/prettier
                    //     i.carrito.pricePerBox *
                    //         ((100 - i.carrito.discount) / 100)
                    // );
                    console.log(
                        "i.carrito.pricePerBox / i.carrito.unitPerBox",
                        i.carrito.pricePerBox / i.carrito.unitPerBox
                    );
                    i.carrito.subTotal =
                        (i.carrito.pricePerBox / i.carrito.unitPerBox) *
                        i.quantity;

                    i.carrito.subTotalDiscount = i.carrito.subTotal;
                    // Math.trunc(
                    //     i.carrito.subTotal * ((100 - i.carrito.discount) / 100)
                    // );
                    console.log(i.name, { ...i.carrito });
                }
            }
        }
    );

    return { escalaDescuento };
}
