import { ref } from "vue";
import axios from "axios";
import useAuth from "./useAuth";
import useCarrito from "./useCarrito";
import useCatalogo from "./useCatalogo";
import useLitior, { parseSembradoresOrder } from "./useLitior";
import useDownload from "./useDownload";

const apiFeria = axios.create({
    baseURL: `${process.env.VUE_APP_MAIN_SERVICE}`,
    headers: {
        "Content-Type": "application/json",
    },
});

export default function usePedidos() {
    const { getToken, user } = useAuth();
    const { resumen } = useCarrito();
    const { itemsOnCart } = useCatalogo();
    const { createPDF } = useLitior();
    const { downloadPdf } = useDownload();
    const pedidos = ref([]);

    const getPedidos = async () => {
        const response = await apiFeria.post(
            "orders/customreport",
            {
                status: 3,
                orderSAP: null,
                customerID: user.value.compania_sap,
                type: null,
            },
            {
                headers: { token: getToken() },
            }
        );

        if (response.status !== 200) {
            //Handle Error
        }

        pedidos.value = response.data.findAllbyStatus;
    };

    const getReporte = async () => {
        const response = await apiFeria.post(
            "orders/customreport",
            {
                status: 3,
                orderSAP: null,
                customerID: null,
                type: null,
            },
            {
                headers: { token: getToken() },
            }
        );

        if (response.status !== 200) {
            //Handle Error
        }

        pedidos.value = response.data.findAllbyStatus;
    };

    const createCarrito = async () => {
        const items = itemsOnCart.value.map((i) => {
            return {
                sku: i.sku,
                image: i.image,
                description: i.name,
                quantity: i.quantity,
                price: i.carrito.pricePerBox,
                pricetax: i.carrito.pricePerBox,
                total: i.carrito.subTotal,
                totaltax: i.carrito.subTotal,
                sector: i.sector,
                category: i.category,
                source: i.carrito.source,
                umb: i.custom_attributes[0].presentation,
                discount: i.carrito.discount,
                discountprice: i.carrito.pricePerBoxDiscount,
                discountpricetax: i.carrito.pricePerBoxDiscount,
                discounttotal: i.carrito.subTotalDiscount,
                discounttotaltax: i.carrito.subTotalDiscount,
                unitperbox: i.custom_attributes[0].unitperbox,
            };
        });

        const direcciondespacho = user.value.carrito?.delivery
            ? `${user.value.carrito.delivery.detalle} - ${user.value.carrito.delivery.ciudad}, ${user.value.carrito.delivery.departamento}`
            : "";
        console.log(createCarrito);
        const response = await apiFeria.post(
            "cart/addtocart",
            {
                createCartInput: {
                    customerSAP: user.value.compania_sap,
                    customerID: user.value.compania_sap,
                    deliverycustomerSAP:
                        user.value.carrito?.delivery.destinatario_sap,
                    chanel: user.value.feria.canal,
                    deliverytype: 2,
                    paymenttype: 2,
                    source: "7P09",
                    country: "co",
                    total: resumen.subtotal,
                    totaltax: resumen.subtotal,
                    discounttotal: resumen.total,
                    discounttotaltax: resumen.total,
                    service: "Sem",
                    status: 1,
                    salesorg: "7001",
                    creditdays: "30",
                    customer_attributes: {
                        despacho_pnc: user.value.feria.despacho_pnc,
                        despacho_ppc: user.value.feria.despacho_ppc,
                        direcciondespacho,
                        nombrecliente: user.value.nombre_compania,
                        correo: user.value.correo_compania,
                    },
                },
                createProductInput: items,
            },
            {
                headers: { token: getToken() },
            }
        );
        console.log(response);
        if (response.status !== 200 || response.data.status_code === 0) {
            //Handle Error
            throw Error("Error al crear el carrito de la negociacion");
        }
    };

    const createOrder = async () => {
        const response = await apiFeria.post(
            "orders/neworder",
            {
                customerID: user.value.compania_sap,
                direcciondestino: user.value.carrito
                    ? `${user.value.carrito.delivery.detalle} - ${user.value.carrito.delivery.ciudad}, ${user.value.carrito.delivery.departamento}`
                    : "",
                destinatarioSAP: user.value.compania_sap,
            },
            {
                headers: { token: getToken() },
            }
        );
        console.log(response);
        if (response.status !== 200 || response.data.status_code !== 1) {
            //Handle Error
            throw Error("Error al crear la orden");
        }

        return response.data.cartEnabled.findbyCustomer[0];
    };

    const setFileNameToCart = async (orderInfo, fileName) => {
        const response = await apiFeria.put("cart/updatecart", {
            updateCartInput: {
                _id: orderInfo._id,
                ordersource: fileName,
                updated_at: "",
            },
        });

        if (response.status !== 200 || response.data.status_code !== 1) {
            //Handle Error
            throw Error("Error al guardar el nombre del archivo");
        }
    };

    const parsePdfName = (fileName) => {
        return fileName.replace(".pdf", "");
    };

    const procesarPedido = async () => {
        await createCarrito();
        const orderInfo = await createOrder();
        console.log(orderInfo);
        try {
            const pdfData = parseSembradoresOrder({
                ...orderInfo,
                discounttotaltax: resumen.total,
                products: itemsOnCart.value,
            });
            const fileName = await createPDF(pdfData);
            await setFileNameToCart(orderInfo, fileName);
            await downloadPdf(
                `${process.env.VUE_APP_LITIOR_SERVICE}v0/view/${parsePdfName(
                    fileName
                )}`,
                `${orderInfo.customerID}-${orderInfo.idservice}.pdf`
            );
        } catch (error) {
            console.log(error);
        }
    };

    return { pedidos, getPedidos, getReporte, procesarPedido };
}
