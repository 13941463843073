<template>
    <div class="col-12 col-md-6 col-lg-5 col-xl-4 p-1">
        <div class="border rounded shadow-sm p-2 bg-custom">
            <div class="d-flex justify-content-between">
                <span>SUBTOTAL</span>
                <span>{{ divisaFormat(resumen.subtotal) }}</span>
            </div>
            <div class="d-flex justify-content-between">
                <!-- <span>DESCUENTO</span>
                <span>- {{ divisaFormat(resumen.descuento) }}</span> -->
                <span>&nbsp;</span>
                <span>&nbsp;</span>
            </div>
            <hr class="my-1" />
            <div class="d-flex justify-content-between">
                <span>TOTAL</span>
                <span class="fw-bolder">{{ divisaFormat(resumen.total) }}</span>
            </div>
            <!-- <p class="fs-8 mb-0">
                NOTA: TODOS LOS PRECIOS SON CON RETIRO EN PLANTA
            </p> -->
        </div>
    </div>
</template>
<script>
import useCarrito from "@/services/useCarrito";
import { divisaFormat } from "@/services/useDivisas";

export default {
    name: "ProductSumary",
    setup() {
        const { resumen } = useCarrito();

        return { resumen, divisaFormat };
    },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/cambiagro.scss";
span,
span.title {
    font-size: 0.95em;
}
.bg-custom {
    background-color: $gray-100;
}
</style>
