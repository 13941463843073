<template>
    <div>
        <div
            :id="'mainCarousel_' + id"
            class="carousel slide"
            data-bs-ride="carousel"
            ref="refCarousel"
        >
            <div class="carousel-inner">
                <div
                    class="carousel-item"
                    v-for="(img, i) in imgCarousel"
                    :key="i"
                    :class="{ active: i === 0 }"
                >
                    <img :src="img" class="d-block w-100" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { Carousel } from "bootstrap";

export default {
    setup() {
        const id = ref(Math.floor(Math.random() * 1000));
        const refCarousel = ref();
        const bsCarousel = ref();
        const imgCarousel = ref([
            // "https://storage.googleapis.com/assets_cambiagro/assets/images/sem-banner1.png",
            // "https://storage.googleapis.com/assets_cambiagro/assets/images/sem-banner2.png",
            // "https://storage.googleapis.com/assets_cambiagro/assets/images/sem-banner3.png",
            // "https://storage.googleapis.com/assets_cambiagro/assets/images/sem-banner4.png",
            // "https://storage.googleapis.com/assets_cambiagro/assets/images/sem-banner5.png",
            "https://storage.googleapis.com/assets_cambiagro/assets/images/ferias/banner1.png",
            "https://storage.googleapis.com/assets_cambiagro/assets/images/ferias/banner2.png",
            "https://storage.googleapis.com/assets_cambiagro/assets/images/ferias/banner3.png",
            "https://storage.googleapis.com/assets_cambiagro/assets/images/ferias/banner4.png",
            "https://storage.googleapis.com/assets_cambiagro/assets/images/ferias/banner5.png",
            "https://storage.googleapis.com/assets_cambiagro/assets/images/ferias/banner6.png",
            "https://storage.googleapis.com/assets_cambiagro/assets/images/ferias/banner7.png",
        ]);
        onMounted(() => {
            bsCarousel.value = new Carousel(refCarousel.value, {
                interval: 3000,
                pause: false,
                ride: "carousel",
                touch: false,
            });
        });
        return { id, refCarousel, imgCarousel };
    },
};
</script>
<style lang="scss" scoped>
img,
.carousel {
    max-width: 768px;
}
</style>
