import { createRouter, createWebHistory } from "vue-router";
import LoginView from "../views/LoginView";
import ProductListView from "../views/ProductListView";
import CheckoutView from "../views/CheckoutView";
import PedidosView from "../views/PedidosView";
import ReporteView from "../views/ReporteView";

const routes = [
    {
        path: "/:customerId?/:token?",
        name: "LoginView",
        component: LoginView,
        props: true,
    },
    {
        path: "/fertilizantes",
        name: "fertilizantes",
        component: ProductListView,
        props: { sector: ["NC", "ER", "GC"] },
    },
    {
        path: "/agroquimicos",
        name: "agroquimicos",
        component: ProductListView,
        props: { sector: "PC" },
    },
    {
        path: "/checkout",
        name: "checkout",
        component: CheckoutView,
    },
    {
        path: "/pedidos",
        name: "pedidos",
        component: PedidosView,
    },
    {
        path: "/reporte",
        name: "reporte",
        component: ReporteView,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0 };
    },
});

// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
    /**  This goes through the matched routes from last to first,
     * finding the closest route with a title.
     * e.g., if we have `/some/deep/nested/route`
     * and `/some`, `/deep`, and `/nested` have titles,
     * `/nested`'s will be chosen.
     */
    const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
        (el) =>
            // eslint-disable-next-line implicit-arrow-linebreak, comma-dangle
            el.parentNode.removeChild(el)
        // eslint-disable-next-line function-paren-newline
    );

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags
        .map((tagDef) => {
            const tag = document.createElement("meta");

            Object.keys(tagDef).forEach((key) => {
                tag.setAttribute(key, tagDef[key]);
            });

            // We use this to track which meta tags we create so we don't interfere with other ones.
            tag.setAttribute("data-vue-router-controlled", "");

            return tag;
        })
        // Add the meta tags to the document head.
        .forEach((tag) => document.head.appendChild(tag));

    next();
});
export default router;
