import { useVfm } from "vue-final-modal";

export const usePopup = () => {
    const vfm = useVfm();
    const modalId = Symbol("BannerPopup");

    const closePopup = () => {
        vfm.close(modalId);
    };
    const openPopup = () => {
        if (!sessionStorage.getItem("wasOpened")) {
            sessionStorage.setItem("wasOpened", true);
            vfm.open(modalId);
        }
    };

    return { closePopup, openPopup, modalId };
};
