import axios from "axios";
import { computed, ref } from "vue";
import useAuth from "./useAuth";

const apiFeria = axios.create({
    baseURL: `${process.env.VUE_APP_MAIN_SERVICE}`,
    headers: {
        "Content-Type": "application/json",
    },
});

const items = ref([]);

export default function useCatalogo() {
    const { getToken } = useAuth();

    const getItems = async () => {
        const response = await apiFeria.post(
            "products/getall",
            {
                service: process.env.VUE_APP_SERVICE,
            },
            {
                headers: { token: getToken() },
            }
        );

        if (response.status !== 200) {
            //handler Error
        }

        if (items.value.length) {
            items.value = response.data.getCustomsProduct.map((i) => {
                const localItem = items.value.find((li) => li.sku === i.sku);
                if (localItem) {
                    return { ...localItem, ...i };
                }
            });
        } else {
            items.value = response.data.getCustomsProduct;
        }
        console.log("Cargao");
    };

    const updateItem = (item) => {
        items.value = items.value.map((i) => {
            if (i.sku === item.sku) {
                return item;
            }
            return i;
        });
    };

    const itemsOnCart = computed(() => {
        return items.value.filter((i) => i.quantity > 0);
    });

    const resetCart = async () => {
        items.value = [];
        await getItems();
    };

    return { items, itemsOnCart, getItems, updateItem, resetCart };
}
