import { createApp } from "vue";
import { createVfm } from "vue-final-modal";
import App from "./App.vue";
import router from "./router";
import useAuth from "./services/useAuth";

const authServices = useAuth();
authServices.recoverSession();

createApp(App).use(createVfm()).use(router).mount("#app");
