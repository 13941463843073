<template>
    <tr>
        <td>
            <div
                class="column-product d-flex flex-column flex-lg-row align-items-center justify-content-certer text-center"
            >
                <div
                    class="product-img"
                    :style="{
                        'background-image': `url(${urlImg}${item.image})`,
                    }"
                ></div>
                <span class="product-title"
                    ><strong>{{ item.name }}</strong></span
                >
            </div>
        </td>
        <!-- <td class="text-center">
            <span class="text-danger text-nowrap">{{
                divisaFormat(item.carrito.pricePerBox)
            }}</span>
        </td> -->
        <td class="text-center">
            <span class="text-success text-nowrap">{{
                divisaFormat(item.carrito.pricePerBoxDiscount)
            }}</span>
        </td>
        <td class="text-center">
            <span class="text-dark text-nowrap">{{
                item.carrito.unitPerBox
            }}</span>
        </td>
        <!-- <td class="text-center">
            <span class="text-danger text-nowrap">{{
                divisaFormat(item.carrito.price)
            }}</span>
        </td> -->
        <td class="text-center">
            <span class="text-success text-nowrap">{{
                divisaFormat(item.carrito.priceDiscount)
            }}</span>
        </td>
        <td class="text-center">
            <span class="text-dark text-nowrap">{{ item.quantity }}</span>
        </td>
        <!-- <td class="text-center">
            <span class="text-danger text-nowrap">{{
                divisaFormat(item.carrito.subTotal)
            }}</span>
        </td> -->
        <td class="text-center">
            <span class="text-success text-nowrap">{{
                divisaFormat(item.carrito.subTotalDiscount)
            }}</span>
        </td>
        <td>
            <div class="d-flex align-items-center">
                <router-link :to="link"
                    ><i role="button" class="fa fa-edit text-secondary"></i
                ></router-link>
                <i
                    role="button"
                    class="ms-2 fa fa-trash text-danger"
                    @click="handleDeleteRow"
                ></i>
            </div>
        </td>
    </tr>
</template>
<script>
import { computed } from "vue";
import { divisaFormat } from "@/services/useDivisas";

export default {
    name: "ProductSumaryRow",
    props: {
        item: Object,
    },
    setup(props, { emit }) {
        const handleDeleteRow = () => {
            emit("onUpdate", { ...props.item, quantity: 0, carrito: null });
        };
        const link = computed(() => {
            switch (props.item.sector) {
                case "NC":
                case "ER":
                    return "/fertilizantes?sku=" + props.item.sku;
                case "PC":
                    return "/agroquimicos?sku=" + props.item.sku;
            }
            return "/";
        });
        return {
            divisaFormat,
            handleDeleteRow,
            urlImg: "https://storage.googleapis.com/assets_cambiagro/assets/images/",
            link,
        };
    },
};
</script>
<style lang="scss" scoped>
.table th:first-child,
.table td:first-child {
    position: sticky;
    left: 0;
    background-color: white;
}
.column-product {
    min-width: 100px;
}
.product-img {
    width: 55px;
    height: 55px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.product-title,
td {
    font-size: 0.78rem;
    text-align: center;
}

@media (min-width: 1088px) {
    .column-product {
        min-width: 200px;
    }
    .product-title {
        font-size: 0.85rem;
    }
    td {
        font-size: 1rem;
    }
}
</style>
