<template lang="">
    <div
        class="loader d-flex flex-column justify-content-center align-items-center"
    >
        <lottie-animation
            :path="data"
            :loop="true"
            :autoPlay="true"
            :width="256"
            :height="256"
        />

        <slot />
    </div>
</template>
<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
    name: "LoaderAnimation",
    components: { LottieAnimation },
    props: {
        data: String,
    },
};
</script>
<style lang="scss" scoped>
.loader {
    padding: 0;
    position: absolute;
    width: calc(100vw);
    height: calc(100vh);
    background-color: white;
    z-index: 99999999;
}
</style>
