<template>
    <div>
        <div class="container-fluid pt-3" v-if="!isLoading">
            <div class="table-responsive">
                <table class="table align-middle">
                    <thead>
                        <tr>
                            <td colspan="5"><strong>TOTAL</strong></td>
                            <!-- <td class="text-center">
                                {{ divisaFormat(totaltax) }}
                            </td> -->
                            <td class="text-center">
                                {{ divisaFormat(discounttotaltax) }}
                            </td>
                            <!-- <td class="text-center">
                                {{ divisaFormat(discount) }}
                            </td> -->
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="5">
                                <strong>TOTAL USD - TC 3.920</strong>
                            </td>
                            <!-- <td class="text-center">
                                {{ divisaFormat(totaltaxusd) }}
                            </td> -->
                            <td class="text-center">
                                {{ divisaFormat(discounttotaltaxusd) }}
                            </td>
                            <!-- <td class="text-center">
                                {{ divisaFormat(discountusd) }}
                            </td> -->
                            <td></td>
                            <td></td>
                        </tr>
                        <tr class="text-center">
                            <th class="text-start">CLIENTE</th>
                            <th>CÓDIGO</th>
                            <th>CÓDIGO SAP</th>
                            <th>FECHA</th>
                            <th>ESTADO NEGOCIACION</th>
                            <!-- <th>TOTAL</th> -->
                            <th>TOTAL A PAGAR</th>
                            <!-- <th>DESCUENTO</th> -->
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ReporteRow
                            v-for="p in pedidos"
                            :key="p._id"
                            :pedido="p"
                        />
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="5"><strong>TOTAL</strong></td>
                            <!-- <td class="text-center">
                                {{ divisaFormat(totaltax) }}
                            </td> -->
                            <td class="text-center">
                                {{ divisaFormat(discounttotaltax) }}
                            </td>
                            <!-- <td class="text-center">
                                {{ divisaFormat(discount) }}
                            </td> -->
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="5">
                                <strong>TOTAL USD - TC 3.920</strong>
                            </td>
                            <!-- <td class="text-center">
                                {{ divisaFormat(totaltaxusd) }}
                            </td> -->
                            <td class="text-center">
                                {{ divisaFormat(discounttotaltaxusd) }}
                            </td>
                            <!-- <td class="text-center">
                                {{ divisaFormat(discountusd) }}
                            </td> -->
                            <td></td>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <div class="list-content container-fluid" v-else>
            <lottie-animation
                path="loading.json"
                :loop="true"
                :autoPlay="true"
                :width="256"
                :height="256"
            />
        </div>
    </div>
</template>
<script>
import { computed, onBeforeMount, ref } from "vue";
import ReporteRow from "@/components/reporte/ReporteRow.vue";
import usePedidos from "@/services/usePedidos";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { divisaFormat } from "@/services/useDivisas";

export default {
    name: "ReporteView",
    components: { ReporteRow, LottieAnimation },
    setup() {
        const { pedidos, getReporte } = usePedidos();
        const isLoading = ref(true);
        const totaltax = computed(() => {
            let sum = 0;
            for (const p of pedidos.value) {
                sum += p.totaltax;
            }
            return sum;
        });
        const discounttotaltax = computed(() => {
            let sum = 0;
            for (const p of pedidos.value) {
                sum += p.discounttotaltax;
            }
            return sum;
        });
        const discount = computed(() => {
            return totaltax.value - discounttotaltax.value;
        });
        const totaltaxusd = computed(() => {
            if (totaltax.value) return totaltax.value / 3920;
            return 0;
        });
        const discounttotaltaxusd = computed(() => {
            if (discounttotaltax.value) return discounttotaltax.value / 3920;
            return 0;
        });
        const discountusd = computed(() => {
            if (discount.value) return discount.value / 3920;
            return 0;
        });
        onBeforeMount(async () => {
            try {
                await getReporte();
            } catch (error) {
                console.log(error);
            }
            isLoading.value = false;
        });
        return {
            pedidos,
            isLoading,
            totaltax,
            discounttotaltax,
            discount,
            divisaFormat,
            totaltaxusd,
            discounttotaltaxusd,
            discountusd,
        };
    },
};
</script>
<style lang="scss" scoped>
th,
td {
    font-size: 0.78rem;
}
@media (min-width: 1088px) {
    td {
        font-size: 1rem;
    }
}
</style>
<style></style>
