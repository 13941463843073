<template>
    <VueFinalModal
        class="confirm-modal"
        content-class=""
        overlay-transition="vfm-fade"
        content-transition="vfm-fade"
    >
        <div class="modal show d-block">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-body">
                        <h1 class="fw-bolder fs-5 text-center">
                            Entrega a domicilio
                        </h1>
                        <p class="text-secondary text-center">
                            Seleccione la bodega de destino para los productos
                            Agroquímicos y Foliares
                        </p>
                        <!-- <div class="d-flex justify-content-center mb-3">
                            <button
                                class="btn btn-sm border border-dark rounded-1"
                                :class="{
                                    'btn-dark': btnSelected === 'domicilio',
                                }"
                                @click="handleSelect('domicilio')"
                            >
                                A Domiclio
                            </button>
                        </div> -->
                        <div class="row" v-if="btnSelected === 'domicilio'">
                            <div
                                class="col-lg-12 mb-2"
                                v-for="(d, i) in destinatarios"
                                :key="i"
                            >
                                <div
                                    class="border border-secondary-subtle rounded-2 p-2 h-100"
                                >
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            :id="`dest_${i}`"
                                            v-model="destinatario_id"
                                            :value="i"
                                        />
                                        <label
                                            class="form-check-label"
                                            :for="`dest_${i}`"
                                        >
                                            <span class="mb-0 fs-6"
                                                ><strong>{{
                                                    d.detalle
                                                }}</strong></span
                                            ><br />
                                            <span class="mb-0 fs-8"
                                                >{{ d.ciudad }} -
                                                {{ d.departamento }}</span
                                            ><br />
                                            <span class="mb-0 fs-7"
                                                >Telefono:
                                                <strong>{{
                                                    d.telefono_destinatario
                                                }}</strong></span
                                            >
                                        </label>
                                    </div>
                                    <!-- <p class="mb-0 fs-8">
                                        {{ d.ciudad }} - {{ d.departamento
                                        }}<br />
                                        telefono:
                                        {{ d.telefono_destinatario }}
                                    </p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-dark text-light"
                            @click="$emit('onClose')"
                        >
                            Cancelar
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary text-light"
                            @click="handleSelectDestinatario"
                            :disabled="!(destinatario_id >= 0)"
                        >
                            Aceptar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </VueFinalModal>
</template>
<script>
import { computed, ref } from "vue";
import { VueFinalModal } from "vue-final-modal";
import useAuth from "@/services/useAuth";

export default {
    name: "DeliverySelectorModal",
    props: {
        title: {
            type: String,
            required: false,
        },
    },
    components: { VueFinalModal },
    setup(props, { emit }) {
        const { getUser } = useAuth();
        const destinatario_id = ref();

        const user = getUser();

        const btnSelected = ref("domicilio");
        const delivery = ref();

        const handleSelect = (value) => {
            btnSelected.value = value;
        };

        const destinatarios = computed(() => {
            return user.destinatarios.filter((d) => d.estado_id === 1);
        });

        const handleSelectDestinatario = () => {
            const destinatario = destinatarios.value[destinatario_id.value];
            emit("onConfirm", destinatario);
        };

        return {
            btnSelected,
            handleSelect,
            delivery,
            destinatario_id,
            destinatarios,
            handleSelectDestinatario,
        };
    },
};
</script>
<style lang="scss" scoped></style>
