<template lang="">
    <div class="table-responsive">
        <table class="table align-middle">
            <thead>
                <tr>
                    <th class="align-middle text-center">PRODUCTO</th>
                    <th class="align-middle text-center">PRECIO POR CAJA</th>
                    <!-- <th class="align-middle text-center">
                        PRECIO CON DESCUENTO POR CAJA
                    </th> -->
                    <th class="align-middle text-center">UNIDADES POR CAJA</th>
                    <th class="align-middle text-center">PRECIO POR UNIDAD</th>
                    <!-- <th class="align-middle text-center">
                        PRECIO CON DESCUENTO POR UNIDAD
                    </th> -->
                    <th class="align-middle text-center">UNIDADES</th>
                    <th class="align-middle text-center">SUB-TOTAL</th>
                    <!-- <th class="align-middle text-center">
                        SUB-TOTAL CON DESCUENTO
                    </th> -->
                </tr>
            </thead>
            <tbody>
                <PedidoProductRow
                    v-for="i in items"
                    :key="i.sku"
                    :item="i"
                    @onUpdate="(item) => $emit('onUpdate', item)"
                />
            </tbody>
            <tfoot>
                <tr>
                    <td>TOTAL</td>
                    <!-- <td></td>
                    <td></td>
                    <td></td> -->
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="text-center">
                        <span class="text-success text-nowrap">{{
                            divisaFormat(subTotalDiscount)
                        }}</span>
                    </td>
                    <td></td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>
<script>
import { computed } from "vue";
import PedidoProductRow from "./PedidoProductRow.vue";
import { divisaFormat } from "@/services/useDivisas";
export default {
    name: "AgroquimicosSumaryList",
    components: { PedidoProductRow },
    props: { items: Array },
    setup(props) {
        const subTotalDiscount = computed(() => {
            let sum = 0;
            for (const i of props.items) {
                sum += i.discounttotaltax;
            }

            return sum;
        });

        return { subTotalDiscount, divisaFormat };
    },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/cambiagro.scss";
.table th:first-child,
.table td:first-child {
    position: sticky;
    left: 0;
    background-color: white;
}

th,
td {
    font-size: 0.65rem;
}
@media (min-width: 1088px) {
    th {
        font-size: 0.6rem;
    }
    td {
        font-size: 0.8rem;
    }
}
</style>
