import axios from "axios";
// import pdfFIle from "@/assets/docs/205135-CLIENTE_CAMBIAGRO_GT-OFERTA-11064.pdf";

export default function useDownload() {
    const downloadPdf = async (url, label) => {
        const response = await axios.get(url, { responseType: "blob" });
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = label;
        link.click();
        URL.revokeObjectURL(link.href);
    };

    return { downloadPdf };
}
